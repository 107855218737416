import './bootstrap';
import '../css/app.css';
import '../css/client/client.css';

import {createApp, h} from 'vue';
import {createInertiaApp} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';

//LOCALIZATION
import {i18nVue} from 'laravel-vue-i18n';

//SENTRY
import ConfigSentry from "@/Config/ConfigSentry.js";

//BRAINKIT
import {BrainkitFrontend} from "@ryokutech/brainkit_frontend";
import "@ryokutech/brainkit_frontend/dist/brainkit_frontend.css";

//SCROLL TO
import VueScrollTo from 'vue-scrollto';

//PINIA
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Translation from "@/Components/Translation/Translation.vue";

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//VARIABLES
const appName = import.meta.env.VITE_APP_NAME || 'WARNING: APP NAME NOT SET';
const appUrl = import.meta.env.VITE_APP_URL || 'WARNING: APP URL NOT SET';
const appEnvironment = import.meta.env.VITE_APP_ENV || 'WARNING: APP ENVIRONMENT NOT SET';
const sentryDns = import.meta.env.VITE_SENTRY_DNS || '';

createInertiaApp({
    progress: false,
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {

        if (!isMobileDevice()) {
            alert('This app is not supported on desktop. Please use a mobile device.');
            return;
        }

        //VUE APP
        const app = createApp({render: () => h(App, props)});

        //SENTRY
        if (sentryDns !== 'null') {
            const sentryInstrumentation = new ConfigSentry(app, appUrl, sentryDns, appEnvironment);
            sentryInstrumentation.initializeSentry();
        }

        app.use(plugin)
            .use(pinia)
            .use(VueScrollTo)
            .use(ZiggyVue, Ziggy)
            .use(i18nVue, {
                fallbackLang: 'en',
                resolve: async lang => {
                    const langs = import.meta.glob('../../lang/*.json');
                    return await langs[`../../lang/${lang}.json`]();
                }
            })
            .use(BrainkitFrontend)
            .component('Translation', Translation)
            .mount(el);

        return app;
    },
});

function isMobileDevice() {
    return /Mobi|Android/i.test(window.navigator.userAgent);
}
