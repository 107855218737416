// Assuming necessary imports are already at the top of your file

import {router} from '@inertiajs/vue3';
import * as Sentry from "@sentry/vue";

class ConfigSentry {
    constructor(app, appUrl, sentryDns, appEnvironment) {
        this.app = app;
        this.appUrl = appUrl;
        this.sentryDns = sentryDns;
        this.appEnvironment = appEnvironment;
    }

    inertiaRoutingInstrumentation(customStartTransaction, startTransactionOnPageLoad = true, startTransactionOnLocationChange = true) {
        let activeTransaction;
        let name;

        if (startTransactionOnPageLoad) {
            name = route().current();
            activeTransaction = customStartTransaction({
                name,
                op: 'pageload',
                metadata: {
                    source: 'route',
                },
            });
        }

        if (startTransactionOnLocationChange) {
            router.on('before', (_to, _from) => {
                if (activeTransaction) {
                    activeTransaction.finish();
                }

                const newName = route().current();

                if (newName !== name) {
                    activeTransaction = customStartTransaction({
                        name: newName,
                        op: 'navigation',
                        metadata: {
                            source: 'route',
                        },
                    });
                }
            });

            router.on('finish', () => {
                activeTransaction.setName(route().current(), 'route');
            });
        }
    }

    initializeSentry() {

        if (this.appEnvironment === 'local') {
            return;
        }

        Sentry.init({
            app: this.app,
            dsn: this.sentryDns,
            environment: this.appEnvironment,
            integrations: [
                new Sentry.BrowserTracing({
                    tracePropagationTargets: ["localhost", this.appUrl],
                    routingInstrumentation: this.inertiaRoutingInstrumentation.bind(this),
                }),
                new Sentry.Replay(),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }
}

export default ConfigSentry;
