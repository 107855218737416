<script setup>
import {trans} from "laravel-vue-i18n";
import {computed} from "vue";

const props = defineProps({
    tkey: String,
    replacements: Object // This object holds dynamic data for the translation
})


const text = computed(() => {
    return trans(`${props.tkey}`, {...props.replacements})
})
</script>

<template>
    <span v-html="text"/>
</template>
